<script>
import { Pie, mixins } from "vue-chartjs";
export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: {
    data: Object,
    id: String,
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    /**
     * Update Information Chart
     * @method updateData
     */
    updateData(dataNew) {
      this.data = dataNew;
      this.renderPieChart();
    },

    /**
     * Create Chart
     * @method renderPieChart
     */
    renderPieChart() {
      this.renderChart(this.chartData, {
        borderWidth: "10px",
        hoverBackgroundColor: "red",
        hoverBorderWidth: "10px",
      });
    },
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
};
</script>
