<template>
  <b-row>
    <!-- TITLE -->
    <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
      <h5>
        <strong>
          {{ $t("CAMPUS.TITLE8") }}
        </strong>
      </h5>
    </b-col>

    <v-col
      cols="6"
      md="6"
      v-for="(amenity, index) in amenityOne"
      :key="index"
      class="group-fields-bottom"
    >
      <v-checkbox
        v-model="campus_amenitys"
        :label="amenity.name_en"
        :value="amenity.amenityTypeId"
        hide-details
      />
    </v-col>
  </b-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campus_amenitys: [],
    };
  },
  created() {
    if (this.edit) {
      if (
        this.campusPage &&
        this.campusPage.campus_amenitys &&
        this.campusPage.campus_amenitys.length
      ) {
        this.campusPage.campus_amenitys.forEach(item => {
          this.campus_amenitys.push(item.amenityTypeId);
        });
      }
    }
  },
  computed: {
    ...mapGetters(["amenityTypes", "campusPage"]),
    amenityOne() {
      const data = this.amenityTypes.filter(amenity => {
        return amenity.typeId === 1;
      });
      return data;
    },
  },
  watch: {
    campus_amenitys: {
      handler: function() {
        this.$emit("update:facilities-info", this.campus_amenitys);
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
