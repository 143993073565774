<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
        <h5>
          <strong>
            {{ $t("CAMPUS.TRANSPORT_TITLE") }}
          </strong>
        </h5>
      </b-col>
      <!-- BUS -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-checkbox
          v-model="info.stopsBus"
          :label="$t('CAMPUS.BUS_TITLE')"
          hide-details
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          :disabled="!isDisabledStopsBus"
          v-model="info.linesBus"
          :label="$t('CAMPUS.LINES_TITLE')"
          :rules="[validations.policyText(info.linesBus, isDisabledStopsBus)]"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.distanceValueBus"
          :label="$t('CAMPUS.UNIT_MED')"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-select
          v-model="info.distanceUnitBus"
          outlined
          dense
          item-value="id"
          :label="$t('CAMPUS.UNIT')"
          :items="UnitTypes"
          :error-messages="errors"
        ></v-select>
      </b-col>
    </b-row>
    <b-row>
      <!-- METRO -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-checkbox
          v-model="info.stopsMetro"
          :label="$t('CAMPUS.METRO_TITLE')"
          hide-details
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          :disabled="!isDisabledStopsMetro"
          v-model="info.linesMetro"
          :label="$t('CAMPUS.LINES_TITLE')"
          :rules="[
            validations.policyText(info.linesMetro, isDisabledStopsMetro),
          ]"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.distanceValueMetro"
          :label="$t('CAMPUS.UNIT_MED')"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-select
          v-model="info.distanceUnitMetro"
          outlined
          dense
          item-value="id"
          :label="$t('CAMPUS.UNIT')"
          :items="UnitTypes"
          :error-messages="errors"
        ></v-select>
      </b-col>
    </b-row>
    <b-row>
      <!-- METRO -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-checkbox
          v-model="info.stopsTram"
          :label="$t('CAMPUS.TRANVIA_TITLE')"
          hide-details
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.linesTram"
          :disabled="!isDisabledStopsTram"
          :label="$t('CAMPUS.LINES_TITLE')"
          :rules="[validations.policyText(info.linesTram, isDisabledStopsTram)]"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.distanceValueTram"
          :label="$t('CAMPUS.UNIT_MED')"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-select
          v-model="info.distanceUnitTram"
          outlined
          dense
          item-value="id"
          :label="$t('CAMPUS.UNIT')"
          :items="UnitTypes"
          :error-messages="errors"
        ></v-select>
      </b-col>
    </b-row>
    <b-row>
      <!-- TREN -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-checkbox
          v-model="info.stopsTrain"
          :label="$t('CAMPUS.TREN_TITLE')"
          hide-details
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.linesTrain"
          :disabled="!isDisabledStopsTrain"
          :label="$t('CAMPUS.LINES_TITLE')"
          :rules="[
            validations.policyText(info.linesTrain, isDisabledStopsTrain),
          ]"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.distanceValueTrain"
          :label="$t('CAMPUS.UNIT_MED')"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-select
          v-model="info.distanceUnitTrain"
          outlined
          dense
          item-value="id"
          :label="$t('CAMPUS.UNIT')"
          :items="UnitTypes"
          :error-messages="errors"
        ></v-select>
      </b-col>
    </b-row>
    <hr />
    <br />
    <br />
    <div class="fixed-pricing-component">
      <b-row>
        <b-col lg="4" md="6" sm="12" cols="12">
          <h5>
            <strong>
              {{ $t("CAMPUS.AGE") }}
            </strong>
          </h5>
          <br />
          <!-- TITLE -->
          <table class="table-content">
            <thead>
              <tr>
                <th>Age Range.</th>
                <th>% of students</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ $t("CAMPUS.AGE_1") }}
                </td>
                <td>
                  <v-text-field
                    v-model="info.ageStudents_1"
                    @change="getTotal"
                    required
                    outlined
                    dense
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("CAMPUS.AGE_2") }}
                </td>
                <td>
                  <v-text-field
                    v-model="info.ageStudents_2"
                    @change="getTotal"
                    required
                    outlined
                    dense
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("CAMPUS.AGE_3") }}
                </td>
                <td>
                  <v-text-field
                    v-model="info.ageStudents_3"
                    @change="getTotal"
                    required
                    outlined
                    dense
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("CAMPUS.AGE_4") }}
                </td>
                <td>
                  <v-text-field
                    v-model="info.ageStudents_4"
                    @change="getTotal"
                    required
                    outlined
                    dense
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {{ $t("CAMPUS.AGE_5") }}
                </td>
                <td>
                  <v-text-field
                    v-model="info.ageStudents_5"
                    @change="getTotal"
                    required
                    outlined
                    dense
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" md="4" sm="4" cols="12" class="mb-5">
          <v-expand-transition>
            <h6
              class="text-danger pt-1 pb-6"
              v-show="sumAge > 0 && sumAge != 100"
            >
              {{ $t("CAMPUS.ERROR_CHARTS") }}
            </h6>
          </v-expand-transition>
          <v-expand-transition>
            <h6 class="text-success pt-1 pb-6" v-show="sumAge == 100">
              100%
            </h6>
          </v-expand-transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        stopsBus: null,
        linesBus: null,
        stopsMetro: null,
        linesMetro: null,
        stopsTram: null,
        linesTram: null,
        stopsTrain: null,
        linesTrain: null,
        distanceUnit: null,
        distanceValue: null,
        ageStudents_1: 0,
        ageStudents_2: 0,
        ageStudents_3: 0,
        ageStudents_4: 0,
        ageStudents_5: 0,
      },
      sumAge: 0,
      // VALIDATIONS
      validations: {
        ...Rules,
      },
      UnitTypes: [
        {
          id: 1,
          text: "Mt",
        },
        {
          id: 2,
          text: "Ft",
        },
      ],
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.address = addressData.formatted_address;
      this.form.addressComponent = addressData;
    },
    getTotal() {
      this.sumAge =
        Number(this.form.ageStudents_1) +
        Number(this.form.ageStudents_2) +
        Number(this.form.ageStudents_3) +
        Number(this.form.ageStudents_4) +
        Number(this.form.ageStudents_5);
      /*if (this.sumAge > 0 && this.sumAge !== 100) {
        this.$emit("save_disabled", true);
      } else {
        this.$emit("save_disabled", true);
      }*/
    },
    /**
     * get info google place for cityComponent
     * @method getCityData
     * @returns 'cityComponent' object whit data
     */
    getCityData(cityData) {
      this.form.googlePlaceId = cityData.place_id;
      this.form.cityComponent = cityData;
    },

    loadData() {
      if (this.edit) {
        this.form.stopsBus = this.campusPage.stopsBus;
        this.form.linesBus = this.campusPage.linesBus;
        this.form.stopsMetro = this.campusPage.stopsMetro;
        this.form.linesMetro = this.campusPage.linesMetro;
        this.form.stopsTram = this.campusPage.stopsTram;
        this.form.linesTram = this.campusPage.linesTram;
        this.form.stopsTrain = this.campusPage.stopsTrain;
        this.form.linesTrain = this.campusPage.linesTrain;
        this.form.distanceUnitBus = this.campusPage.distanceUnitBus;
        this.form.distanceValueBus = this.campusPage.distanceValueBus;
        this.form.distanceUnitMetro = this.campusPage.distanceUnitMetro;
        this.form.distanceValueMetro = this.campusPage.distanceValueMetro;
        this.form.distanceUnitTram = this.campusPage.distanceUnitBus;
        this.form.distanceValueTram = this.campusPage.distanceValueTram;
        this.form.distanceUnitTrain = this.campusPage.distanceUnitTrain;
        this.form.distanceValueTrain = this.campusPage.distanceValueTrain;
        this.form.ageStudents_1 = this.campusPage.ageStudents_1;
        this.form.ageStudents_2 = this.campusPage.ageStudents_2;
        this.form.ageStudents_3 = this.campusPage.ageStudents_3;
        this.form.ageStudents_4 = this.campusPage.ageStudents_4;
        this.form.ageStudents_5 = this.campusPage.ageStudents_5;
      }
      this.getTotal();
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["campusPage", "Currencies", "company"]),
    info() {
      return this.form;
    },
    isDisabledStopsBus() {
      return this.form.stopsBus;
    },
    isDisabledStopsMetro() {
      return this.form.stopsMetro;
    },
    isDisabledStopsTram() {
      return this.form.stopsTram;
    },
    isDisabledStopsTrain() {
      return this.form.stopsTrain;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:additional-info", data);
      },
      deep: true,
    },
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
