<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
        <h5>
          <strong>
            {{ $t("CAMPUS.TITLE8_1") }}
          </strong>
        </h5>
      </b-col>
      <!-- TITLE -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.total_classrooms"
          :label="$t('CAMPUS.TOTAL_CLASSROOMS')"
          required
          outlined
          dense
        />
      </b-col>
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-text-field
          v-model="info.total_computers"
          :label="$t('CAMPUS.TOTAL_COMPUTERS')"
          required
          outlined
          dense
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- TV SERVICE -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.tvService"
          :label="$t('CAMPUS.TV')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="4" cols="12" v-if="info.tvService">
        <v-select
          v-model="info.tvServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- BOARD SERVICE -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.boardService"
          :label="$t('CAMPUS.BOARD')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="4" cols="12" v-if="info.boardService">
        <v-select
          v-model="info.boardServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- AIR CONDITIONING -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.airService"
          :label="$t('CAMPUS.AIR_CONDITIONING')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="3" cols="12" v-if="info.airService">
        <v-select
          v-model="info.airServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- HEATING -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.heatingService"
          :label="$t('CAMPUS.HEATING')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="3" cols="12" v-if="info.heatingService">
        <v-select
          v-model="info.heatingServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- NATURAL LIGHT -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.naturalLightService"
          :label="$t('CAMPUS.NATURAL_LIGHT')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="3" cols="12" v-if="info.naturalLightService">
        <v-select
          v-model="info.naturalLightServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- WHEELCHAIR ACCESSIBILITY -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.wheelChairService"
          :label="$t('CAMPUS.WHEELCHAIR_ACCESSIBILITY')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="3" cols="12" v-if="info.wheelChairService">
        <v-select
          v-model="info.wheelChairServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
      <!-- DIGITAL CLASSES TABLET -->
      <b-col lg="3" md="3" sm="12" cols="12" class="input-check">
        <v-checkbox
          v-model="info.digitalTabletService"
          :label="$t('CAMPUS.DIGITAL_CLASSES_TABLET')"
          class="mt-2"
        />
      </b-col>
      <!-- DURATION TYPE -->
      <b-col lg="3" md="3" sm="3" cols="12" v-if="info.digitalTabletService">
        <v-select
          v-model="info.digitalTabletServiceQty"
          :items="typeFacilities"
          :label="$t('CAMPUS.IN')"
          item-text="text"
          item-value="value"
          hide-details="auto"
          dense
          outlined
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        total_classrooms: null,
        total_computers: null,
        tvService: false,
        tvServiceQty: null,
        boardService: false,
        boardServiceQty: null,
        airService: false,
        airServiceQty: null,
        heatingService: false,
        heatingServiceQty: null,
        naturalLightService: false,
        naturalLightServiceQty: null,
        wheelChairService: false,
        wheelChairServiceQty: null,
        digitalTabletService: false,
        digitalTabletServiceQty: null,
      },
      typeDuration: [
        { text: "Day", id: 2 },
        { text: "Week", id: 3 },
      ],
      typeFacilities: [
        { text: "In all the classroms", value: 1 },
        { text: "In most of the classroms", value: 2 },
        { text: "In some classroms", value: 3 },
      ],
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    loadData() {
      if (this.edit) {
        this.form.total_classrooms = this.campusPage.total_classrooms;
        this.form.total_computers = this.campusPage.total_computers;
        this.form.tvService = this.campusPage.tvService;
        this.form.tvServiceQty = this.campusPage.tvServiceQty;
        this.form.boardService = this.campusPage.boardService;
        this.form.boardServiceQty = this.campusPage.boardServiceQty;
        this.form.airService = this.campusPage.airService;
        this.form.airServiceQty = this.campusPage.airServiceQty;
        this.form.heatingService = this.campusPage.heatingService;
        this.form.heatingServiceQty = this.campusPage.heatingServiceQty;
        this.form.naturalLightService = this.campusPage.naturalLightService;
        this.form.naturalLightServiceQty = this.campusPage.naturalLightServiceQty;
        this.form.wheelChairService = this.campusPage.wheelChairService;
        this.form.wheelChairServiceQty = this.campusPage.wheelChairServiceQty;
        this.form.digitalTabletService = this.campusPage.digitalTabletService;
        this.form.digitalTabletServiceQty = this.campusPage.digitalTabletServiceQty;
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["campusPage", "Currencies", "company"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:additional-info", data);
      },
      deep: true,
    },
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
