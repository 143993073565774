<template>
  <div class="accommodation-settings">
    <!-- BUTTONS -->
    <Buttons
      :textCancel="$t('GENERAL.CANCEL')"
      v-on:cancel="cancel"
      :textSave="$t('GENERAL.SAVE')"
      v-on:save="save"
      :save_loading="save_loading"
      :btnDuplicate="false"
      :btnDelete="true"
      :delete_loading="delete_loading"
      :textDelete="$t('GENERAL.DELETE')"
      v-on:delete="isShowDialogDelete = true"
    />
    <!-- FORM LODGING -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <!-- BASIC INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100 margin-top--30">
        <div class="card-body">
          <BasicInformation
            :key="`basic-${keyUpdateElements}`"
            :basic-info.sync="basicInformation"
            :edit="true"
          />
          <!-- PHOTO PROFILE -->
          <b-col class="p-0">
            <Profile
              :key="`profile-${keyUpdateElements}`"
              ref="imageProfile"
              :button_text="$t('GENERAL.UPLOAD_LOGO')"
              entity="campus"
              :profile="false"
              :get_data_campus_page="true"
            />
          </b-col>
        </div>
      </div>

      <!-- ADDITIONAL INFORMATION -->
      <div class="card card-custom card-stretch gutter-b w-100 margin-top--30">
        <div class="card-body">
          <AdditionalInformation
            :additional-info.sync="additionalInformation"
            :edit="true"
          />
        </div>
      </div>

      <!-- FINANCIAL DATA -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <FinancialData
            :key="`financial-${keyUpdateElements}`"
            :financial-info.sync="financialInformation"
            :title="$t('CAMPUS.TITLE5')"
            :edit="true"
            :campus="true"
            :Policy="false"
          />
        </div>
      </div>

      <!-- POLICY CANCELLATION -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <b-row class="mb-0">
            <!-- GENERAL -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12">
                  <h5>
                    <strong>
                      {{ $t("SETTINGS.USER.BUSSINESS.TITLE3") }}
                    </strong>
                  </h5>
                </b-col>
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="standardCancellationPolicies"
                    :label="$t('CAMPUS.GENERAL_CANCELLATION_POLICY')"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row class="mb-0">
            <!-- FREE -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE14") }}
                      <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            class="tooltip-info"
                          >
                            <font-awesome-icon icon="info-circle" />
                          </v-btn>
                        </template>
                        test
                        <span>{{ $t("CAMPUS.FREE_INFO") }}</span>
                      </v-tooltip>
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="freeCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.FREE_CANCELLATION_CHECK')"
                    @click="freeCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <!-- Nunber of days -->
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFree }">
                    {{ $t("SETTINGS.USER.BUSSINESS.POLICY_CANCELLATION") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="cancellationPolicy"
                    :suffix="$t('CAMPUS.DAYS')"
                    :disabled="!isDisabledFree"
                    :rules="[
                      validations.policyNumber(
                        cancellationPolicy,
                        isDisabledFree
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-textarea
                    v-model="cancellationPoliciesDescription"
                    :label="$t('SETTINGS.USER.BUSSINESS.POLICY')"
                    :disabled="!isDisabledFree"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- FREE POSTPONE -->
          <b-row class="mb-0">
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pt-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE15") }}
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="freePostponementCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.FREE_POSTPONEMENT_CANCELLATION_CHECK')"
                    @click="freePostponementCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFreePostponement }">
                    {{ $t("CAMPUS.FREE_POSTPONE_POLICY") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="freePostponePolicy"
                    :suffix="$t('CAMPUS.DAYS')"
                    :disabled="!isDisabledFreePostponement"
                    :rules="[
                      validations.policyNumber(
                        freePostponePolicy,
                        isDisabledFreePostponement
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <em :class="{ 'text-muted': !isDisabledFreePostponement }">
                    {{ $t("CAMPUS.FREE_POSTPONEMENT") }}
                  </em>
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="2" md="2" sm="3" cols="4" class="pb-0">
                  <v-text-field
                    type="number"
                    v-model="postponePolicy"
                    :suffix="$t('CAMPUS.MONTH')"
                    :disabled="!isDisabledFreePostponement"
                    :rules="[
                      validations.policyNumber(
                        postponePolicy,
                        isDisabledFreePostponement
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="postponeGeneralPolicy"
                    :label="$t('CAMPUS.GENERAL_POSTPONE_POLICY')"
                    :disabled="!isDisabledFreePostponement"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- VISA -->
          <b-row class="mb-0">
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pt-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE18") }}
                    </strong>
                  </h5>
                </b-col>
                <!-- CHECK BOX -->
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="visaCancellationCheck"
                    class="align-label"
                    :label="$t('CAMPUS.VISA_CANCELLATION_CHECK')"
                    @click="visaCancellationCheckMethod"
                  />
                </b-col>
              </b-row>
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12">
                  <v-textarea
                    v-model="visaCancellationPolicy"
                    :label="$t('CAMPUS.VISA_CANCELLATION')"
                    :disabled="!isDisabledVisa"
                    :rules="[
                      validations.policyText(
                        visaCancellationPolicy,
                        isDisabledVisa
                      ),
                    ]"
                    outlined
                    dense
                  ></v-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- PAYMENT POLICY -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <b-row>
            <!-- TITLE -->
            <b-col cols="12">
              <b-row class="mb-6">
                <b-col lg="12" md="12" sm="12" cols="12">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE19") }}
                    </strong>
                  </h5>
                  <div class="info-payment">
                    <p>{{ $t("CAMPUS.PAYMENT_DESCRIPTION") }}</p>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- SPECIAL PAYMENT POLICY -->
            <b-col cols="12">
              <b-row class="mb-0">
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <h5>
                    <strong>
                      {{ $t("CAMPUS.TITLE20") }}
                    </strong>
                    <br />
                    <span class="font-weight-b">
                      {{ $t("CAMPUS.PAYMENT_DESCRIPTION_SUB") }}
                    </span>
                  </h5>
                </b-col>
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <v-checkbox
                    v-model="PaymentPolicy"
                    class="align-label m-0"
                    :label="$t('CAMPUS.PAYMENT_CHECK_SPECIAL')"
                    @click="PaymentPolicyCheckMethod"
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicySpecialTerm -->
              <b-row class="mb-0">
                <b-col lg="4" md="4" sm="4" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_TERMS") }}
                  </span>
                  <v-text-field
                    type="number"
                    v-model="PaymentPolicyLongTerm"
                    :suffix="$t('CAMPUS.WEEKS')"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyNumber(
                        PaymentPolicyLongTerm,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicyPercent -->
              <b-row class="mb-0">
                <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_PERCENTAGE") }}
                  </span>
                  <v-text-field
                    type="number"
                    v-model="PaymentPolicyPercent"
                    :suffix="$t('CAMPUS.PERCENTAGE')"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyPercentage(
                        PaymentPolicyPercent,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                    outlined
                    dense
                  />
                </b-col>
              </b-row>

              <!-- PaymentPolicyRecurrence -->
              <b-row class="mb-0">
                <!-- TITLE -->
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                  <span
                    :class="{ 'text-muted': !isDisabledPaymentPolicy }"
                    class="font-size-1-2"
                  >
                    {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE") }}
                  </span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :color="
                          isDisabledPaymentPolicy ? 'primary' : 'secondary'
                        "
                        icon
                        v-bind="attrs"
                        v-on="on"
                        class="tooltip-info"
                      >
                        <font-awesome-icon icon="info-circle" />
                      </v-btn>
                    </template>
                    <span>
                      {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE_TOOLTIP") }}
                      <br />
                      {{ $t("CAMPUS.PAYMENT_POLICY_RECURRENCE_TOOLTIP_2") }}
                    </span>
                  </v-tooltip>
                </b-col>
                <b-col lg="12" md="12" sm="12" cols="12" class="pb-0 pt-0">
                  <v-radio-group
                    v-model="PaymentPolicyRecurrence"
                    :disabled="!isDisabledPaymentPolicy"
                    :rules="[
                      validations.policyText(
                        PaymentPolicyRecurrence,
                        isDisabledPaymentPolicy
                      ),
                    ]"
                  >
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM1')"
                      :value="BEFORE_COURSE"
                    />
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM2')"
                      :value="QUARTERLY_ADVANCE"
                    />
                    <v-radio
                      :label="$t('CAMPUS.PAYMENT_POLICY_RECURRENCE_ITEM3')"
                      :value="MONTHLY_ADVANCE"
                    />
                  </v-radio-group>
                </b-col>
              </b-row>

              <!-- SPECIAL PAYMENT CONDITIONS -->
              <b-row>
                <!-- PaymentPolicySpecial -->
                <b-col cols="12">
                  <b-row class="mb-0">
                    <b-col lg="12" md="12" sm="12" cols="12" class="pb-0">
                      <v-checkbox
                        v-model="PaymentPolicySpecial"
                        class="align-label m-0"
                        @click="PaymentPolicySpecialCheckMethod"
                        :label="$t('CAMPUS.PAYMENT_POLICY_SPECIAL_CONDITION')"
                      />
                    </b-col>
                  </b-row>
                  <b-row class="mb-0">
                    <b-col lg="6" md="6" sm="6" cols="12" class="pb-0">
                      <span
                        :class="{
                          'text-muted': !isDisabledPaymentPolicySpecial,
                        }"
                        class="font-size-1-2"
                      >
                        {{ $t("CAMPUS.PAYMENT_POLICY_SPECIAL") }}
                      </span>
                      <v-text-field
                        type="number"
                        v-model="PaymentPolicySpecialPercent"
                        :disabled="!isDisabledPaymentPolicySpecial"
                        :rules="[
                          validations.policyNumber(
                            PaymentPolicySpecialPercent,
                            isDisabledPaymentPolicySpecial
                          ),
                        ]"
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                            </template>
                            <span class="d-block">
                              {{ $t("CAMPUS.PAYMENT_POLICY_SPECIAL_TOOLTIP") }}
                            </span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- COURSE DEFAULT -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <CourseDefault :course-info.sync="courseInformation" :edit="true" />
        </div>
      </div>

      <!-- Multimedia Gallery -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <Gallery
            :key="`gallery-${keyUpdateElements}`"
            :title="$t('CAMPUS.TITLE6')"
            :showInfo="true"
            :infoText1="$t('CAMPUS.INFO1')"
            :files="dataGallery"
            @data="filesGallery = $event"
            ref="actionsGallery"
            entity="campus"
          />
          <b-col lg="4" md="4" sm="6" cols="12">
            <v-text-field
              v-model="featuredVideoUrl"
              :label="$t('CAMPUS.VIDEO')"
              outlined
              dense
            />
          </b-col>
        </div>
      </div>

      <!-- Nationality Mix -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <b-row>
            <b-col lg="12" md="12" sm="12" cols="12">
              <h5 class="d-inline-block mt-2">
                <strong>
                  {{ $t("CAMPUS.TITLE7") }}
                </strong>
              </h5>
              <small>
                <br />
                {{ $t("CAMPUS.SUB_TITLE7_1") }}
                {{ $t("CAMPUS.SUB_TITLE7_2") }}
              </small>
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <ContentChart
                :key="`chart-1-${keyUpdateElements}`"
                v-bind:data="campusPage.campus_nationality_mixs"
                :label="$t('CAMPUS.CAMPUSNATIONALITY.OPTION1')"
                :chart-info.sync="dataChart1"
                :type="1"
              />
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <ContentChart
                :key="`chart-2-${keyUpdateElements}`"
                v-bind:data="campusPage.campus_nationality_mixs"
                :label="$t('CAMPUS.CAMPUSNATIONALITY.OPTION2')"
                :chart-info.sync="dataChart2"
                :type="2"
              />
            </b-col>
            <b-col lg="4" md="4" sm="12" cols="12">
              <ContentChart
                :key="`chart-3-${keyUpdateElements}`"
                v-bind:data="campusPage.campus_nationality_mixs"
                :label="$t('CAMPUS.CAMPUSNATIONALITY.OPTION3')"
                :chart-info.sync="dataChart3"
                :type="3"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <b-row>
        <!-- Description -->
        <b-col lg="8" md="8" sm="12" cols="12" class="mb-5">
          <div class="card card-custom card-stretch gutter-b w-100">
            <div class="card-body">
              <DescriptionData
                :description-info.sync="descriptionInformation"
                :edit="true"
                @reload-translations="reloadTranslations"
              />
            </div>
          </div>
        </b-col>

        <!-- Translation -->
        <b-col lg="4" md="4" sm="12" cols="12" class="mb-5">
          <Translation
            ref="translationComponent"
            :fields="dataTranslation"
            model="campus"
            :skeleton="10"
            maxHeight="456px"
          />
        </b-col>
      </b-row>

      <!-- FACILITIES -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <Facilities
            :key="`facilities-${keyUpdateElements}`"
            :facilities-info.sync="amenitysInformation"
            :edit="true"
          />
        </div>
      </div>
      <!-- FACILITIES -->
      <div class="card card-custom card-stretch gutter-b w-100">
        <div class="card-body">
          <AdditionalFacilities
            :additional-info.sync="additionalFacilities"
            :edit="true"
          />
        </div>
      </div>
      <!-- DISPLAY OPTIONS -->
      <div class="card card-custom card-stretch gutter-b w-100 margin-top--30">
        <div class="card-body">
          <b-row>
            <!-- TITLE -->
            <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
              <h5>
                <strong>
                  {{ $t("CAMPUS.TITLE12") }}
                </strong>
              </h5>
            </b-col>

            <!-- Calendar Week Start -->
            <b-col lg="4" md="4" sm="12" cols="12">
              <v-select
                v-model="calendar_format"
                :items="itemsCalendar"
                :label="$t('CAMPUS.CALENDAR_WEEK')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </b-col>

            <!-- Calendar Week Start -->
            <b-col lg="4" md="4" sm="12" cols="12">
              <v-select
                v-model="date_format_id"
                :items="itemsFormat"
                :label="$t('CAMPUS.DATE_FORMAT')"
                item-text="text"
                item-value="value"
                dense
                outlined
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </v-form>

    <!-- MODAL DELETE -->
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card class="pt-3">
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deleteCampus">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  UPDATE_CAMPUS,
  DELETE_CAMPUS,
} from "@/core/services/store/general/campus.module";
import { GET_CAMPUS } from "@/core/services/store/general/campus_page.module";
import BasicInformation from "./components/BasicInformation";
import AdditionalInformation from "./components/AdditionalInformation";
import AdditionalFacilities from "./components/AdditionalFacilities";
import FinancialData from "@/view/content/components/FinancialData";
import CourseDefault from "./components/CourseDefault";
import ContentChart from "@/view/content/components/ContentChart";
import Facilities from "./components/Facilities";
import Gallery from "@/view/content/components/images/Gallery";
import Buttons from "@/view/content/components/Buttons";
import Translation from "@/view/content/components/translation/Index";
import DescriptionData from "./components/DescriptionData";
import Profile from "@/view/content/components/images/Profile";
import Rules from "@/core/services/utils/validations";

export default {
  components: {
    BasicInformation,
    AdditionalInformation,
    AdditionalFacilities,
    FinancialData,
    CourseDefault,
    ContentChart,
    Facilities,
    Gallery,
    Buttons,
    Translation,
    DescriptionData,
    Profile,
  },
  data() {
    return {
      valid: true,
      isShowDialogDelete: false,
      dialogCommissions: false,
      save_loading: false,
      save_disabled: false,
      duplicate_loading: false,
      delete_loading: false,
      dataGallery: null,
      filesGallery: null,
      featuredVideoUrl: null,
      basicInformation: null,
      additionalInformation: null,
      additionalFacilities: null,
      financialInformation: null,
      courseInformation: null,
      dataChart1: null,
      dataChart2: null,
      dataChart3: null,
      descriptionInformation: null,
      amenitysInformation: null,
      loader: false,
      photo: null,
      calendar_format: null,
      date_format_id: null,
      cancellationPolicy: null,
      cancellationPoliciesDescription: null,
      postponePolicy: null,
      standardCancellationPolicies: null,
      postponeGeneralPolicy: null,
      visaCancellationPolicy: null,
      freeCancellationCheck: false,
      freePostponementCancellationCheck: false,
      visaCancellationCheck: false,
      freePostponePolicy: null,
      campusNationalityMixs: null,
      keyUpdateElements: 0,
      PaymentPolicy: false,
      PaymentPolicyLongTerm: null,
      PaymentPolicyPercent: null,
      PaymentPolicySpecial: false,
      PaymentPolicyRecurrence: null,
      PaymentPolicySpecialPercent: null,
      BEFORE_COURSE: 1,
      QUARTERLY_ADVANCE: 2,
      MONTHLY_ADVANCE: 3,
      // ITEMS
      dataTranslation: [
        {
          title: this.$i18n.t("CAMPUS.SCHOOL_DESCRIPTION"),
          type: "description",
        },
        {
          title: this.$i18n.t("CAMPUS.CITY_DESCRIPTION"),
          type: "description2",
        },
        {
          title: this.$i18n.t("CAMPUS.ENVIROMENT_DESCRIPTION"),
          type: "description3",
        },
      ],
      itemsCalendar: [
        {
          text: this.$t("CAMPUS.CALENDAR.OPT1"),
          value: 0,
        },
        {
          text: this.$t("CAMPUS.CALENDAR.OPT2"),
          value: 1,
        },
      ],
      itemsFormat: [
        {
          text: this.$t("CAMPUS.FORMAT.OPT2"),
          value: 0,
        },
        {
          text: this.$t("CAMPUS.FORMAT.OPT1"),
          value: 1,
        },
      ],
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * return campus page
     * @method cancel
     */
    cancel() {
      this.$router.push("/campus");
    },

    /**
     * update info of the school selected
     * @method save
     * @returns 'campus' object whit the data updated
     */
    async save() {
      // Validate fields required
      if (!this.$refs.form.validate()) {
        this.$bvToast.toast(this.$t("MESSAGE.ERROR.DESCRIPTION_NEW"), {
          title: this.$t("MESSAGE.ERROR.TITLE_NEW"),
          variant: "danger",
          solid: true,
        });
      } else {
        this.save_loading = true;
        let data = {
          ...this.basicInformation,
          ...this.additionalInformation,
          ...this.additionalFacilities,
          ...this.financialInformation,
          ...this.courseInformation,
          ...this.descriptionInformation,
          featuredVideoUrl: this.featuredVideoUrl,
          campusId: this.campusPage.campusId,
          calendar_format: this.calendar_format,
          date_format_id: this.date_format_id,
          cancellationPolicy: this.cancellationPolicy,
          cancellationPoliciesDescription: this.cancellationPoliciesDescription,
          postponePolicy: this.postponePolicy,
          freePostponePolicy: this.freePostponePolicy,
          standardCancellationPolicies: this.standardCancellationPolicies,
          postponeGeneralPolicy: this.postponeGeneralPolicy,
          visaCancellationPolicy: this.visaCancellationPolicy,
          PaymentPolicy: this.PaymentPolicy,
          PaymentPolicyLongTerm: this.PaymentPolicyLongTerm,
          PaymentPolicyPercent: this.PaymentPolicyPercent,
          PaymentPolicyRecurrence: this.PaymentPolicyRecurrence,
          PaymentPolicySpecial: this.PaymentPolicySpecial,
          PaymentPolicySpecialPercent: this.PaymentPolicySpecialPercent,
        };

        // SAVE PHOTO PROFILE)
        const dataImage = await this.$refs.imageProfile.getData();

        if (dataImage.noImage) {
          this.photo = null;
        } else {
          this.photo = dataImage.url ? dataImage : this.photo;
        }

        data = {
          ...data,
          photoId: this.photo ? this.photo.fileId : null,
          photo: this.photo,
        };

        // SET TRANSLATE
        if (this.descriptionInformation) {
          const {
            description,
            city_description,
            enviroment_description,
          } = this.descriptionInformation;

          const dataJson = [
            description,
            city_description,
            enviroment_description,
          ];
          this.$refs.translationComponent.getTranslations(
            this.campusPage.campusId,
            dataJson
          );
        }
        // GET PHOTO IDS
        const id_photos = [];
        if (this.filesGallery) {
          this.filesGallery.forEach(photo => {
            id_photos.push(photo.fileId);
          });

          data.photos = id_photos.join();
        } else {
          data.photos = "";
        }

        // Get info Nationality Mix
        data.campus_nationality_mixs = [];

        // SET school_nationality_mixs TYPE 1
        if (Array.isArray(this.dataChart1)) {
          this.dataChart1.forEach(element => {
            if (element.label !== null) {
              data.campus_nationality_mixs.push({
                campusId: this.campusPage.campusId,
                label: element.label,
                percentage: Number(element.percentage),
                campusNationalityMixId: element.id,
                typeId: 1,
              });
            }
          });
        }

        // SET school_nationality_mixs TYPE 2
        if (Array.isArray(this.dataChart2)) {
          this.dataChart2.forEach(element => {
            if (element.label !== null) {
              data.campus_nationality_mixs.push({
                campusId: this.campusPage.campusId,
                label: element.label,
                percentage: Number(element.percentage),
                campusNationalityMixId: element.id,
                typeId: 2,
              });
            }
          });
        }

        // SET school_nationality_mixs TYPE 3
        if (Array.isArray(this.dataChart3)) {
          this.dataChart3.forEach(element => {
            if (element.label !== null) {
              data.campus_nationality_mixs.push({
                campusId: this.campusPage.campusId,
                label: element.label,
                percentage: Number(element.percentage),
                campusNationalityMixId: element.id,
                typeId: 3,
              });
            }
          });
        }

        data.campus_amenitys = this.amenitysInformation;

        this.$store
          .dispatch(UPDATE_CAMPUS, data)
          .then(() => {
            this.save_loading = false;

            this.$bvToast.toast(this.$t("MESSAGE.SUCCESS.DESCRIPTION_NEW"), {
              title: this.$t("MESSAGE.SUCCESS.TITLE_NEW"),
              variant: "success",
              solid: true,
            });
          })
          .catch();
      }
    },

    /**
     * Delete campus seleted
     * @method deleteCampus
     * @returns 'campus' object whit the new data
     */
    deleteCampus() {
      this.isShowDialogDelete = false;
      this.delete_loading = true;
      const { campusId } = this.campusPage;
      this.$store.dispatch(DELETE_CAMPUS, campusId).then(() => {
        this.delete_loading = false;
        this.$router.push("/campus");
      });
    },

    loadData() {
      this.dataGallery = this.campusPage.photos;
      this.featuredVideoUrl = this.campusPage.featuredVideoUrl;
      this.photo = this.campusPage.photo;
      this.calendar_format = this.campusPage.calendar_format;
      this.date_format_id = this.campusPage.date_format_id;
      this.cancellationPolicy = this.campusPage.cancellationPolicy;
      this.cancellationPoliciesDescription = this.campusPage.cancellationPoliciesDescription;
      this.postponePolicy = this.campusPage.postponePolicy;
      this.standardCancellationPolicies = this.campusPage.standardCancellationPolicies;
      this.postponeGeneralPolicy = this.campusPage.postponeGeneralPolicy;
      this.visaCancellationPolicy = this.campusPage.visaCancellationPolicy;
      this.freePostponePolicy = this.campusPage.freePostponePolicy;
      this.campusNationalityMixs = this.campusPage.campus_nationality_mixs;
      this.PaymentPolicy = this.campusPage.PaymentPolicy;
      this.PaymentPolicyLongTerm = this.campusPage.PaymentPolicyLongTerm;
      this.PaymentPolicyPercent = this.campusPage.PaymentPolicyPercent;
      this.PaymentPolicyRecurrence = this.campusPage.PaymentPolicyRecurrence;
      this.PaymentPolicySpecial = this.campusPage.PaymentPolicySpecial;
      this.PaymentPolicySpecialPercent = this.campusPage.PaymentPolicySpecialPercent;
      this.keyUpdateElements += 1;

      const {
        cancellationPolicy,
        cancellationPoliciesDescription,
        freePostponePolicy,
        postponePolicy,
        postponeGeneralPolicy,
        visaCancellationPolicy,
      } = this.campusPage;

      // CHECK freeCancellationCheck
      if (cancellationPolicy || cancellationPoliciesDescription) {
        this.freeCancellationCheck = true;
      }

      // CHECK freePostponementCancellationCheck
      if (freePostponePolicy || postponePolicy || postponeGeneralPolicy) {
        this.freePostponementCancellationCheck = true;
      }

      // CHECK visaCancellationCheck
      if (visaCancellationPolicy) {
        this.visaCancellationCheck = true;
      }
    },

    reloadTranslations(data) {
      if (this.$refs.translationComponent) {
        const { description, city_description, enviroment_description } = data;
        // SET TRANSLATE
        const dataJson = [
          description,
          city_description,
          enviroment_description,
        ];
        this.$refs.translationComponent.getTranslations(
          this.campusPage.campusId,
          dataJson
        );
      }
    },
    fetchData() {
      this.$store.dispatch(GET_CAMPUS, this.$route.params.id).then(() => {
        this.loadData();
      });
    },

    /**
     * show or hide form information
     */
    freeCancellationCheckMethod() {
      this.cancellationPolicy = this.freeCancellationCheck
        ? this.campusPage.cancellationPolicy
        : null;
      this.cancellationPoliciesDescription = this.freeCancellationCheck
        ? this.campusPage.cancellationPoliciesDescription
        : null;
    },

    /**
     * show or hide form information
     */
    freePostponementCancellationCheckMethod() {
      this.freePostponePolicy = this.freePostponementCancellationCheck
        ? this.campusPage.freePostponePolicy
        : null;
      this.postponePolicy = this.freePostponementCancellationCheck
        ? this.campusPage.postponePolicy
        : null;
      this.postponeGeneralPolicy = this.freePostponementCancellationCheck
        ? this.campusPage.postponeGeneralPolicy
        : null;
    },

    /**
     * show or hide form information
     */
    visaCancellationCheckMethod() {
      this.visaCancellationPolicy = this.visaCancellationCheck
        ? this.campusPage.visaCancellationPolicy
        : null;
    },

    /**
     * show or hide form information
     */
    PaymentPolicyCheckMethod() {
      this.PaymentPolicyLongTerm = this.PaymentPolicy
        ? this.campusPage.PaymentPolicyLongTerm
        : null;
      this.PaymentPolicyPercent = this.PaymentPolicy
        ? this.campusPage.PaymentPolicyPercent
        : null;
      this.PaymentPolicyRecurrence = this.PaymentPolicy
        ? this.campusPage.PaymentPolicyRecurrence
        : null;
    },

    /**
     * show or hide form information
     */
    PaymentPolicySpecialCheckMethod() {
      this.PaymentPolicySpecialPercent = this.PaymentPolicySpecial
        ? this.campusPage.PaymentPolicySpecialPercent
        : null;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("CAMPUS.TITLE"),
        route: "/campus",
      },
      { title: this.$t("GENERAL.EDIT") },
    ]);

    // SET TRANSLATE
    const dataJson = [
      this.campusPage.description,
      this.campusPage.city_description,
      this.campusPage.enviroment_description,
    ];

    this.campusPage.description &&
      this.$refs.translationComponent.getTranslations(
        this.campusPage.campusId,
        dataJson
      );

    this.$refs.imageProfile.setData(this.photo);
  },

  async created() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(["campusPage"]),
    picture() {
      if (this.photo !== null && typeof this.photo !== "undefined") {
        return this.photo.url;
      } else {
        return `${process.env.VUE_APP_CDN_PATH}images/default/ynsitu.png`;
      }
    },
    isLoader() {
      return this.loader;
    },
    isDisabledFree() {
      return this.freeCancellationCheck;
    },
    isDisabledVisa() {
      return this.visaCancellationCheck;
    },
    isDisabledFreePostponement() {
      return this.freePostponementCancellationCheck;
    },
    isDisabledPaymentPolicy() {
      return this.PaymentPolicy;
    },
    isDisabledPaymentPolicySpecial() {
      return this.PaymentPolicySpecial;
    },
  },
  watch: {
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "fetchData",
  },
};
</script>

<style scoped>
.info-payment {
  background-color: #f6f6f6;
  padding: 10px;
  color: #534d4d;
  font-style: italic;
  border: 1px solid #e6dfdf;
}

.font-size-1-2 {
  font-size: 1.2rem;
}
.font-weight-b {
  font-weight: 100;
}
</style>
