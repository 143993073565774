<template>
  <b-row>
    <!-- OPTIONS CHART -->
    <b-col lg="8" md="8" sm="8" cols="8">
      <h5 class="d-inline-block mt-2">
        <strong>
          {{ label }}
        </strong>
      </h5>
    </b-col>

    <!-- NEW DATA CHART -->
    <b-col lg="4" md="4" sm="4" cols="4">
      <v-btn depressed color="primary" @click="dialog = true" v-if="!showChart">
        {{ $t("GENERAL.NEW") }}
      </v-btn>

      <!-- EDIT DATA CHART -->
      <v-btn
        depressed
        color="primary"
        class="float-right d-inline-block"
        @click="dialog = true"
        v-if="showChart"
      >
        {{ $t("GENERAL.EDIT") }}
      </v-btn>
    </b-col>

    <!-- SHOW CHART -->
    <v-col cols="12" md="12">
      <Charts
        :data="chartData"
        ref="chartMethods"
        :class="{ 'd-none': !showChart }"
      />
    </v-col>

    <!-- MODAL EDIT INFORMATION CHART -->
    <v-dialog v-model="dialog" width="500" scrollable>
      <v-card>
        <div class="alert alert-danger m-3" role="alert" v-if="msjerror">
          {{ $t("GENERAL.COMPLETE_INFORMATION") }}
        </div>
        <v-card-title>
          {{ label }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t("CAMPUS.TITLE9") }}
        </v-card-subtitle>
        <v-card-text style="height: 300px;">
          <v-form ref="form" v-model="valid" lazy-validation>
            <table class="table-content-charts">
              <thead>
                <tr>
                  <th>{{ $t("CAMPUS.TITLE10") }}</th>
                  <th>{{ $t("CAMPUS.TITLE11") }}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in editChart"
                  :key="index"
                  class="mb-3 border-row"
                >
                  <td>
                    <v-text-field
                      v-model="item.label"
                      :placeholder="$t('CAMPUS.TITLE10')"
                      :rules="validations.required"
                      outlined
                      dense
                      class="input-min--width-80"
                    />
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :placeholder="$t('CAMPUS.TITLE11')"
                      :rules="validations.percentage"
                      v-model="item.percentage"
                      class="input-min--width-80"
                      @change="getTotal"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <a
                      class="btn btn-icon btn-light btn-hover-danger float-right"
                      style="margin-top:-20px"
                      @click="deleteNat(index)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <v-icon color="red">
                          mdi-delete
                        </v-icon>
                      </span>
                    </a>
                  </td>
                </tr>
                <tr class="mb-3 border-row">
                  <td class="text-right pr-5">
                    <strong :class="{ 'text-danger': sum < 100 }">
                      <h4>
                        {{ $t("CAMPUS.TOTAL") }}
                      </h4>
                    </strong>
                  </td>
                  <td class="text-left">
                    <strong :class="{ 'text-danger': sum < 100 }">
                      <h4>
                        {{ totalchart }}
                      </h4>
                    </strong>
                  </td>
                </tr>
                <tr class="mb-3 border-row">
                  <td class="text-center" colspan="2">
                    <v-expand-transition>
                      <h6 class="text-danger pt-1 pb-6" v-show="sum != 100">
                        {{ $t("CAMPUS.ERROR_CHARTS") }}
                      </h6>
                    </v-expand-transition>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-form>
          <v-btn
            depressed
            color="primary"
            @click="addNat"
            :disabled="sum >= 100"
          >
            {{ $t("GENERAL.ADD") }}
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            color="grey"
            class="float-right d-inline-block mr-3"
            @click="cancelChart"
          >
            {{ $t("GENERAL.CANCEL") }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="float-right d-inline-block"
            @click="saveChart"
            :disabled="sum !== 100"
            :loading="loading_save"
          >
            {{ $t("GENERAL.SAVE") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </b-row>
</template>
<script>
import Charts from "@/view/content/components/Charts";
import Rules from "@/core/services/utils/validations";

export default {
  components: {
    Charts,
  },
  data() {
    return {
      dialog: false,
      valid: true,
      auxContador: 0,
      msjerror: false,
      loading_save: false,
      datasets: {
        hoverBackgroundColor: "#428bca",
        hoverBorderWidth: 10,
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      sum: 0,
      dataChartEditAux: [],
      dataChartEdit: [
        {
          label: null,
          percentage: null,
          backgroundColor: "#89AEB2",
          id: null,
        },
      ],
      bg_colors: [
        "#89AEB2",
        "#F1CDB0",
        "#E7CFC8",
        "#C3E4FD",
        "#8EC9BB",
        "#84B4C8",
        "#B2D9EA",
        "#DFC7C1",
        "#B5DDD1",
        "#9C8ADE",
      ],
      // VALIDATIONS
      validations: {
        valid: true,
        ...Rules,
        percentage: [
          v => {
            if (v === null || v === "") {
              return this.$t("GENERAL.FIELD_ERROR");
            } else {
              if (Number(v) < 0) {
                return this.$t("GENERAL.NEGATIVE_FIELDS");
              }
              if (Number(v) > 100) {
                return this.$t("GENERAL.MORE_100_FIELDS");
              }
              if (!Number.isInteger(Number(v))) {
                return this.$t("GENERAL.DECIMAL_FIELDS");
              }
            }
            return true;
          },
        ],
      },
    };
  },
  props: {
    data: Object,
    label: String,
    type: {
      type: Number,
      default: null,
    },
  },
  methods: {
    /**
     * get total for chart
     * @method getTotal
     * @returns 'sum' whit the total
     */
    getTotal() {
      this.sum = 0;
      this.dataChartEdit.forEach(element => {
        this.sum += Number(element.percentage);
      });
    },

    /**
     * Delete Item dataChartEdit
     * @method deleteNat
     * @returns 'dataChartEdit' whit new data
     */
    deleteNat(index) {
      this.dataChartEdit.splice(index, 1);
    },

    /**
     * Close modal for edit chart
     * @method cancelChart
     */
    cancelChart() {
      const index = this.datasets.labels.length;
      if (this.auxContador === 0) {
        this.dataChartEdit = [];
        this.dataChartEditAux.forEach(element => {
          this.dataChartEdit.push({
            label: element.label,
            percentage: element.percentage,
            backgroundColor: element.backgroundColor,
            id: element.id,
          });
        });
      } else {
        this.dataChartEdit.splice(index, this.auxContador);
      }
      this.dialog = false;
      this.auxContador = 0;
    },

    /**
     * Update data for saveChart
     * @method saveChart
     * @returns 'dataChart' whit new data
     */
    saveChart() {
      if (!this.$refs.form.validate()) {
        this.msjerror = true;
      } else {
        this.msjerror = false;
        this.loading_save = true;
        setTimeout(() => {
          // Empty data datasets and labels
          this.datasets.labels = [];
          this.datasets.datasets[0].data = [];
          this.datasets.datasets[0].backgroundColor = [];

          // EMPTY CHART AUX
          this.dataChartEditAux = [];

          // Update data
          this.dataChartEdit.forEach(element => {
            this.datasets.labels.push(element.label);
            this.datasets.datasets[0].data.push(Number(element.percentage));
            this.datasets.datasets[0].backgroundColor.push(
              element.backgroundColor
            );

            // SET DATA AUX
            this.dataChartEditAux.push({
              label: element.label,
              percentage: element.percentage,
              backgroundColor: element.backgroundColor,
              id: element.id,
            });
          });

          this.$refs.chartMethods.updateData(this.datasets);

          this.loading_save = false;

          this.dialog = false;
        }, 2000);
      }
    },

    /**
     * Update data for dataChartEdit
     * @method addNat
     * @returns 'dataChartEdit' object whit new data
     */
    addNat() {
      this.auxContador = this.auxContador + 1;
      this.dataChartEdit.push({
        label: null,
        percentage: null,
        backgroundColor: this.bg_colors[this.dataChartEdit.length],
        id: null,
      });
    },
  },
  created() {
    if (this.data && this.data.length) {
      this.dataChartEdit = [];
      this.data.forEach((element, index) => {
        if (this.type === null) {
          this.dataChartEdit.push({
            label: element.label,
            percentage: element.percentage,
            backgroundColor: this.bg_colors[index],
            id: element.campusNationalityMixId,
          });

          this.datasets.labels.push(element.label);
          this.datasets.datasets[0].data.push(Number(element.percentage));
          this.datasets.datasets[0].backgroundColor.push(this.bg_colors[index]);
        } else {
          if (Number(this.type) === Number(element.typeId)) {
            this.dataChartEdit.push({
              label: element.label,
              percentage: element.percentage,
              backgroundColor: this.bg_colors[index],
              id: element.campusNationalityMixId,
            });

            this.dataChartEditAux.push({
              label: element.label,
              percentage: element.percentage,
              backgroundColor: this.bg_colors[index],
              id: element.campusNationalityMixId,
            });

            this.datasets.labels.push(element.label);
            this.datasets.datasets[0].data.push(Number(element.percentage));
            this.datasets.datasets[0].backgroundColor.push(
              this.bg_colors[index]
            );
          }
        }
      });
      this.getTotal();
    }
  },
  computed: {
    chartData() {
      return this.datasets;
    },

    showChart() {
      if (this.datasets.labels.length) {
        return true;
      }
      return false;
    },

    editChart() {
      return this.dataChartEdit;
    },

    totalchart() {
      return this.sum;
    },
  },
  watch: {
    dataChartEdit: {
      handler: function() {
        this.$emit("update:chart-info", this.dataChartEdit);
      },
      deep: true,
    },
  },
};
</script>
