<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12" class="mb-5">
        <h5>
          <strong>
            {{ $t("CAMPUS.TITLE1") }}
          </strong>
        </h5>
      </b-col>

      <!-- NAME -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-text-field
          v-model="info.name"
          :label="$t('CAMPUS.NAME')"
          :rules="validations.required"
          required
          outlined
          dense
        />
      </b-col>

      <!-- ADDRESS -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <VueGooglePlaces
          v-model="info.address"
          :api-key="key"
          types="address"
          @placechanged="getAddressData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.address"
            :label="$t('CAMPUS.ADDRESS')"
            :rules="[
              validations.address(
                info.address,
                info.addressComponent,
                edit,
                campusPage.address
              ),
            ]"
            required
            outlined
            dense
          >
            <template v-slot:append>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span class="d-block">
                  {{ $t("CAMPUS.ADDRESS_INFO") }}
                </span>
              </v-tooltip>
            </template>
          </v-text-field>
        </VueGooglePlaces>
      </b-col>

      <!-- CITY -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <VueGooglePlaces
          v-model="info.cityName"
          :api-key="key"
          types="(cities)"
          @placechanged="getCityData"
          class="without-placeholder"
        >
          <v-text-field
            v-model="info.cityName"
            :label="$t('CAMPUS.CITY')"
            :rules="[
              validations.city(
                info.cityName,
                info.cityComponent,
                edit,
                campusPage.google_place
              ),
            ]"
            required
            outlined
            dense
          />
        </VueGooglePlaces>
      </b-col>

      <!-- CURRENCY -->
      <b-col lg="6" md="6" sm="12" cols="12">
        <v-autocomplete
          v-model="info.currencyId"
          :rules="validations.required"
          :items="Currencies"
          :label="$t('CAMPUS.CURRENCY')"
          item-text="newCode"
          item-value="currencyId"
          required
          dense
          outlined
        />
      </b-col>
      <!-- FUNDATION YEAR -->
      <b-col lg="3" md="3" sm="12" cols="12" v-if="edit">
        <v-text-field
          type="number"
          v-model="info.fundationYear"
          :rules="[validations.biggerNumber(1000, 3000)]"
          :label="$t('CAMPUS.FUNDATION_YEAR')"
          outlined
          dense
        />
      </b-col>
      <!-- PHONE -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="edit">
        <v-text-field
          v-model="info.phone"
          :label="$t('CAMPUS.PHONE')"
          outlined
          dense
        />
      </b-col>

      <!-- EMAIL -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="edit">
        <v-text-field
          v-model="info.email"
          :rules="validations.email"
          :label="$t('CAMPUS.EMAIL')"
          outlined
          dense
        />
      </b-col>
      <!-- WEBSITE -->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="edit">
        <v-text-field
          v-model="info.website"
          :label="$t('CAMPUS.WEBSITE')"
          outlined
          dense
        />
      </b-col>
      <!-- EMAIL CONTACT-->
      <b-col lg="4" md="4" sm="12" cols="12" v-if="edit">
        <v-text-field
          v-model="info.email_contact"
          :rules="validations.email"
          :label="$t('CAMPUS.EMAIL_CONTACT')"
          outlined
          dense
        />
      </b-col>
      <!-- MIN AGE -->
      <b-col lg="2" md="3" sm="4" cols="12">
        <v-text-field
          type="number"
          v-model="info.minAge"
          :rules="validations.minAge"
          :label="$t('CAMPUS.AGE_MIN')"
          required
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span class="d-block">
                {{ $t("CAMPUS.MIN_INFO") }}
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </b-col>

      <!-- MAX AGE -->
      <b-col lg="2" md="3" sm="4" cols="12" v-if="edit">
        <v-text-field
          type="number"
          v-model="info.maxAge"
          :rules="[validations.biggerNumber(info.minAge, info.maxAge)]"
          :label="$t('CAMPUS.AGE_MAX')"
          outlined
          dense
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { VueGooglePlaces } from "vue-google-places";
export default {
  components: {
    VueGooglePlaces,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: process.env.VUE_APP_PLACE_KEY,
      form: {
        name: null,
        address: null,
        addressComponent: null,
        cityName: null,
        googlePlaceId: null,
        cityComponent: null,
        currencyId: null,
        phone: null,
        email: null,
        email_contact: null,
        website: null,
        minAge: null,
        maxAge: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    /**
     * get info google place for addressComponent
     * @method getAddressData
     * @returns 'addressComponent' object whit data
     */
    getAddressData(addressData) {
      this.form.address = addressData.formatted_address;
      this.form.addressComponent = addressData;
    },

    /**
     * get info google place for cityComponent
     * @method getCityData
     * @returns 'cityComponent' object whit data
     */
    getCityData(cityData) {
      this.form.googlePlaceId = cityData.place_id;
      this.form.cityComponent = cityData;
    },

    loadData() {
      if (this.edit) {
        this.form.name = this.campusPage.name;
        this.form.address = this.campusPage.address;
        if (this.campusPage.google_place)
          this.form.cityName = this.campusPage.google_place.translation;
        this.form.googlePlaceId = this.campusPage.googlePlaceId;
        this.form.currencyId = this.campusPage.currencyId;
        this.form.phone = this.campusPage.phone;
        this.form.email = this.campusPage.email;
        this.form.email_contact = this.campusPage.email_contact;
        this.form.website = this.campusPage.website;
        this.form.minAge = this.campusPage.minAge;
        this.form.maxAge = this.campusPage.maxAge;
        this.form.fundationYear = this.campusPage.fundationYear;
      }
    },
  },
  created() {
    this.loadData();
  },
  computed: {
    ...mapGetters(["campusPage", "Currencies", "company"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:basic-info", data);
      },
      deep: true,
    },
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
