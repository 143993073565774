<template>
  <div>
    <b-row>
      <!-- TITLE -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("CAMPUS.TITLE4") }}
          </strong>
        </h5>
      </b-col>

      <!-- SCHOOL_DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.description"
          :label="$t('CAMPUS.SCHOOL_DESCRIPTION')"
          dense
          outlined
          :rules="[validations.maxValue(info.description, 3000)]"
          counter="3000"
        />
      </b-col>

      <!-- CITY_DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.city_description"
          :label="$t('CAMPUS.CITY_DESCRIPTION')"
          dense
          outlined
          :rules="[validations.biggerNumber(info.cityDescription, 3000)]"
          counter="3000"
        />
      </b-col>

      <!-- ENVIROMENT_DESCRIPTION -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="info.enviroment_description"
          :label="$t('CAMPUS.ENVIROMENT_DESCRIPTION')"
          dense
          outlined
          :rules="[validations.biggerNumber(info.enviromentDescription, 3000)]"
          counter="3000"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        description: null,
        city_description: null,
        enviroment_description: null,
      },
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.edit) {
        const {
          description,
          city_description,
          enviroment_description,
        } = this.campusPage;
        if (enviroment_description || city_description || description) {
          this.form.description = description;
          this.form.city_description = city_description;
          this.form.enviroment_description = enviroment_description;
          this.$emit("reload-translations", this.form);
        }
      }
    },
  },
  computed: {
    ...mapGetters(["campusPage"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:description-info", data);
      },
      deep: true,
    },
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
