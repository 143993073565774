<template>
  <div>
    <b-row>
      <!-- TITLE 1 -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("CAMPUS.TITLE2") }}
          </strong>
        </h5>
        <i>
          {{ $t("CAMPUS.TITLE2_DES") }}
        </i>
      </b-col>

      <!-- MIN DURATION -->
      <b-col lg="2" md="3" sm="4" cols="12">
        <v-text-field
          type="number"
          v-model="info.courseMinDuration"
          :label="$t('CAMPUS.DURATION_MIN')"
          :rules="validations.integer"
          outlined
          dense
        >
          <template v-slot:append>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-help-circle-outline</v-icon>
              </template>
              <span class="d-block">
                {{ $t("CAMPUS.MIN_DURATION_INFO") }}
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </b-col>

      <!-- MAX DURATION -->
      <b-col lg="2" md="3" sm="4" cols="12">
        <v-text-field
          type="number"
          v-model="info.courseMaxDuration"
          :rules="[
            validations.biggerNumber(
              info.courseMinDuration,
              info.courseMaxDuration
            ),
          ]"
          :label="$t('CAMPUS.DURATION_MAX')"
          outlined
          dense
        />
      </b-col>

      <!-- TYPE DURATION -->
      <b-col lg="2" md="3" sm="4" cols="12">
        <v-select
          v-model="info.courseDurationTypeId"
          :items="options"
          :label="$t('CAMPUS.DURATION')"
          item-text="text"
          item-value="value"
          dense
          outlined
        />
      </b-col>

      <!-- SET_PRICING -->
      <b-col lg="12" md="12" sm="12" cols="12">
        <h5>
          <strong>
            {{ $t("SETTINGS.USER.BUSSINESS.FIELDS.SET_PRICING") }}
          </strong>
        </h5>
        <i>
          {{ $t("CAMPUS.TITLE13") }}
        </i>
      </b-col>

      <!-- transition_rules -->
      <b-col lg="3" md="3" sm="3" cols="12">
        <v-checkbox
          v-model="info.transition_rules"
          :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.SET_PRICING')"
        />
      </b-col>
      <b-col lg="7" md="7" sm="7" cols="0" />

      <!-- transitionRuleTypeId -->
      <b-col lg="6" md="6" sm="6" cols="12" v-if="info.transition_rules">
        <strong>
          {{ $t("SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_DES") }}
        </strong>
        <v-radio-group v-model="info.transitionRuleTypeId" column>
          <v-radio
            :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT1')"
            :value="1"
          />
          <v-radio
            :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT2')"
            :value="2"
          />
          <v-radio
            :label="$t('SETTINGS.USER.BUSSINESS.FIELDS.WHAT_HAPPENS_OPT3')"
            :value="3"
          />
        </v-radio-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Rules from "@/core/services/utils/validations";
import { DEFAULT_DURATION_TYPE } from "@/core/services/utils/durationTypesFormat";

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        courseMinDuration: null,
        courseMaxDuration: null,
        courseDurationTypeId: DEFAULT_DURATION_TYPE,
        transition_rules: null,
        transitionRuleTypeId: null,
      },
      //OPTIONS SELECT
      options: [
        { value: 2, text: this.$t("GENERAL.DAYS") },
        { value: 3, text: this.$t("GENERAL.WEEKS") },
        { value: 4, text: this.$t("GENERAL.MONTHS") },
      ],
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.edit) {
        this.form.courseMinDuration = this.campusPage.courseMinDuration;
        this.form.courseMaxDuration = this.campusPage.courseMaxDuration;
        this.form.courseDurationTypeId = this.campusPage.courseDurationTypeId
          ? this.campusPage.courseDurationTypeId
          : DEFAULT_DURATION_TYPE;
        this.form.transition_rules = Boolean(this.campusPage.transition_rules);
        this.form.transitionRuleTypeId = this.campusPage.transitionRuleTypeId;
      }
    },
  },
  computed: {
    ...mapGetters(["campusPage"]),
    info() {
      return this.form;
    },
  },
  watch: {
    form: {
      handler: function() {
        const data = {
          ...this.form,
        };
        this.$emit("update:course-info", data);
      },
      deep: true,
    },
    campusPage: {
      handler: function() {
        this.loadData();
      },
      deep: true,
    },
    $route: "loadData",
  },
};
</script>
